<template>
  <section class="tracksipment section--shadow-0">
    <div class="container">
      <div class="row quote1top">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="minHeight-200 panel panel-default panel-callout-info">
                <div class="panel-body table-responsive">
                  <h5>{{ getTranslation('activeCargo') }} - {{ stats.count_confirmed }}</h5>

                  <table class="table-3">
                    <thead class="table-3__head">
                    <tr class="table-3__row">
                      <th class="table-3__cell  table-3__hcell" style="width: 10%">№</th>
                      <th class="table-3__cell  table-3__hcell" style="width: 8%">{{ getTranslation('payment') }}</th>
                      <th class="table-3__cell  table-3__hcell" style="width: 15%">{{ getTranslation('trackingStatus') }}</th>
                    </tr>
                    </thead>
                    <tbody class="table-3__body">
                    <tr v-for="item in activeApps" v-bind:key="item.number" @click="clickApp(item)" class="table-3__row table__link-row">
                      <td class="table-3__cell">{{ item.code }}</td>
                      <td class="table-3__cell">
                        <span class="label" :class="{'label-danger':!item.payment,'label-success':item.payment}">
                          {{ getTranslation(item.payment ? 'paid' : 'notPaid') }}
                        </span>
                      </td>
                      <td class="table-3__cell">
                        <span v-if="item.lastTracking" class="label label-info">{{ getTranslation(item.lastTracking.status.title) }}</span>
                        <span v-else class="text-muted">{{ getTranslation('notShippedYet') }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>

                </div>

              </div>
            </div>
            <div class="col-md-6 col-sm-12">

              <div class="minHeight-200 panel panel-default ">
                <div class="panel-body">
                  <div class="details-widget">
                    <div class="left">
                      <img width="90" height="120" :src="$config.noAvatarUrl" alt="Profile photo" class="img-rounded">
                      <div class="btn-group btn-group-sm text-center" role="group">
                        <button type="button" class="btn btn-default btn-xs">{{ getTranslation('changeAvatar') }}</button>
                      </div>
                    </div>
                    <div class="right">
                      <h5>{{ $store.getters['user/getPortalUser'].client.fullName }}</h5>

                      <p v-if="contact.email" class="text">
                        <span class="text-muted"> Email:</span> <span class="black">{{ contact.email }}</span>
                      </p>
                      <p v-if="contact.mobile" class="text">
                        <span class="text-muted">{{ getTranslation('telephone') }}</span>: <span class="black">{{ contact.mobile }}</span>
                      </p>
                      <p class="text">
                        <router-link class="link-1" :to="{name:'portal.client.profile'}" ><span class="black">{{ getTranslation('viewAllData') }}</span></router-link>
                      </p>
                      <p class="text">
                        <a class="link-1" href="javascript:void(0)" @click="portalLogout"><span class="black">{{ getTranslation('logout') }}</span></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div class="col-md-3">
          <SidebarWidget :add-classes="'minHeight-200'"/>
        </div>
      </div>
      <div class="row quote1top">
        <div class="col-md-12 col-sm-12">
          <div class="panel panel-default ">
            <div class="panel-body table-responsive">

              <h5>{{ getTranslation('List of in_action applications') }}</h5>

              <p class="text-muted" v-if="waitingApps.length < 1"> {{ getTranslation('waitingYouSendApp') }} </p>

              <table class="table-3" v-if="waitingApps.length > 0">

                <thead class="table-3__head">
                <tr class="table-3__row">
                  <th class="table-3__cell  table-3__hcell" style="width: 5%">{{ getTranslation('creationDate') }}</th>
                  <th class="table-3__cell  table-3__hcell" style="width: 30%">{{ getTranslation('title') }}</th>
                  <th class="table-3__cell  table-3__hcell" style="width: 5%">{{ getTranslation('approximateShipmentDate') }}</th>
                  <th class="table-3__cell  table-3__hcell" style="width: 10%">{{ getTranslation('pieces') }}</th>
                  <th class="table-3__cell  table-3__hcell" style="width: 10%">{{ getTranslation('weight') }}</th>
                  <th class="table-3__cell  table-3__hcell" style="width: 10%">{{ getTranslation('volume') }}</th>
                </tr>
                </thead>

                <tbody class="table-3__body">
                <tr v-for="item in waitingApps" v-bind:key="item.number" class="table-3__row table__link-row">
                  <td class="table-3__cell">{{ item.creationDate }}</td>
                  <td class="table-3__cell" @click="clickApp(item)">{{ item.title }}</td>
                  <td class="table-3__cell">{{ item.shipment_date }}</td>
                  <td class="table-3__cell">{{ item.totals.totalQuantity }}</td>
                  <td class="table-3__cell">{{ item.totals.totalWeight }} {{ item.totals.w_unit }}</td>
                  <td class="table-3__cell">{{ item.totals.totalVolume_m || 0 }} m<sup>3</sup></td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import helpersMixin from "@/views/portal/mixins/helpers";
import SidebarWidget from "./components/SidebarWidget";

export default {
  name: "Index",
  components: { SidebarWidget },
  mixins: [ helpersMixin ],
  data() {
    return {
      contact: {},
      stats: {},
      waitingApps: [],
      activeApps: [],
    }
  },
  created() {

    this.fetchContact();

    this.fetchClientStats();

    this.fetchWaitingApps();

    this.fetchActiveApps();

    this.setPortalHeadings({
      title: this.getTranslation('account')
    });

  },
  methods: {
    clickApp(item) {
      this.$store.dispatch('portal/setApp', item).then(() => {
        this.$router.push({ name: 'portal.tracking', params: { type: 'app' } }).catch(() => {
        });
      });
    },
    fetchActiveApps() {
      this.axios.get(this.$urls.portal.activeApps.url.replace(':id', this.$store.getters['user/getPortalUser'].client.id))
          .then(response => {
            this.activeApps = response.data.data.items;
          }).catch(() => {
      });
    },
    fetchWaitingApps() {
      this.axios.get(this.$urls.portal.waitingApps.url.replace(':id', this.$store.getters['user/getPortalUser'].client.id))
          .then(response => {
            this.waitingApps = response.data.data;
          }).catch(() => {
      });
    },
    fetchContact() {
      this.axios.get(this.$urls.portal.clientContact.url.replace(':id', this.$store.getters['user/getPortalUser'].client.id))
          .then(response => {
            this.contact = response.data.data;
          }).catch(() => {
      });
    },

    fetchClientStats() {
      this.axios.post(this.$urls.portal.clientStats.url, { client_id: this.$store.getters['user/getPortalUser'].client.id })
          .then(response => {
            this.stats = response.data.data;
          }).catch(() => {
      });
    },

  }
}
</script>

<style scoped>

</style>